import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Fade from "react-reveal/Fade"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default class Projects extends React.Component {
  render() {
    const {
      ymca,
      ff,
      ff2,
      multi,
      multi2,
      multi3,
      tune,
      tune2,
      tune3,
      cackle,
      cackle2,
    } = this.props.data
    return (
      <Layout>
        <SEO
          description="Eli Kinsey's projects."
          title="Projects | Eli Kinsey"
        />
        <section class="projects-container">
          <div className="project-container">
            <Fade>
              <div className="project-images-container">
                <div className="project-image-container">
                  <Img fluid={ymca.childImageSharp.fluid} />
                </div>
              </div>
              <div className="project-content-container ymca">
                <div>
                  <h1>YMCA</h1>
                  <p>
                    The YMCA needed a cheaper alternative to their previous
                    donation platform. Using the Stripe API, NodeJS and Gatsby,
                    I was able to provide the Y with a safer blazing fast
                    donation alternative that cut their previously huge annual
                    service fees down to <strong>ZERO</strong>.
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          <div className="project-container">
            <Fade>
              <div className="project-images-container">
                <div className="project-image-container">
                  <Img fluid={ff.childImageSharp.fluid} />
                </div>
                <div className="project-image-container">
                  <Img fluid={ff2.childImageSharp.fluid} />
                </div>
              </div>
              <div className="project-content-container farm-formal">
                <div>
                  <h1>FARM FORMAL</h1>
                  <p>
                    Farm Formal needed a way to let their clients visualize
                    their wedding venue space. With moveable shapes sized
                    proportionately to their real-life dimensions, you're able
                    to precisely plan just how you want your wedding venue to
                    look. Built with React.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="project-container">
            <Fade>
              <div className="project-images-container">
                <div className="project-image-container">
                  <Img fluid={multi.childImageSharp.fluid} />
                </div>
                <div className="project-image-container">
                  <Img fluid={multi2.childImageSharp.fluid} />
                </div>
                <div className="project-image-container">
                  <Img fluid={multi3.childImageSharp.fluid} />
                </div>
              </div>
              <div className="project-content-container multiple">
                <div>
                  <h1>SAWDUST</h1>
                  <h2>(work in progress)</h2>
                  <p>
                    A tracing tool built to give companies the oppurtunity to
                    show their customers how their products will look in the
                    home. In the example shown, the product is roof tiles. Trace
                    your roof, and swap your tiles with the given options. By
                    extending the options via headless CMS, you can swap out
                    your home with an elephant if you want to.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <div className="project-container">
            <Fade>
              <div className="project-images-container">
                <div className="project-image-container">
                  <Img fluid={tune.childImageSharp.fluid} />
                </div>
                <div className="project-image-container">
                  <Img fluid={tune2.childImageSharp.fluid} />
                </div>
                <div className="project-image-container">
                  <Img fluid={tune3.childImageSharp.fluid} />
                </div>
              </div>
              <div className="project-content-container tune">
                <div>
                  <h1>TUNED OUT</h1>
                  <p>
                    A free music library powered by YouTube and Last.fm. Built
                    with React, NodeJS and MongoDB.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </section>
      </Layout>
    )
  }
}

export const query = graphql`
  query ImageQuery {
    ymca: file(relativePath: { eq: "ymca-5.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ff: file(relativePath: { eq: "ff.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ff2: file(relativePath: { eq: "ff-2.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    multi: file(relativePath: { eq: "multi-1.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    multi2: file(relativePath: { eq: "multi-2.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    multi3: file(relativePath: { eq: "multi-2.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tune: file(relativePath: { eq: "tune.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tune2: file(relativePath: { eq: "tune2.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tune3: file(relativePath: { eq: "tune3.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cackle: file(relativePath: { eq: "cackle.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cackle2: file(relativePath: { eq: "cackle2.png" }) {
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
